<template>
  <div>
    <VInput
      v-model="value"
      class="ma-4"
      hide-details="auto"
      :rules="[(value) => !!value.length || 'Добавьте рабочие дни']"
    />
    <VCard
      class="elevation-3"
      :class="{ 'px-6': !changeSchedule, 'py-6': !changeSchedule }"
    >
      <VRow
        align="center"
        class="header-row"
        no-gutters
      >
        <VCol>
          <span class="header-text">День недели</span>
        </VCol>
        <VDivider vertical />
        <VCol>
          <span class="header-text">Открытие</span>
        </VCol>
        <VDivider vertical />
        <VCol>
          <span class="header-text">Закрытие</span>
        </VCol>
        <VDivider vertical />
      </VRow>
      <VRow
        align="center"
        class="everyday-row"
        no-gutters
      >
        <VCol class="text-center" cols="4">
          <VDialog v-model="everyday.dialog" max-width="50%">
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                v-bind="attrs"
                class="weekday-title"
                :class="{ 'event-none': !changeSchedule }"
                v-on="on"
              >
                Ежедневно
              </VBtn>
            </template>
            <RestaurantScheduleItemSelection
              :item="everyday"
              @change-only-weekdays="changeEveryday($event, true)"
              @item-changed="changeEveryday($event, false)"
            />
          </VDialog>
        </VCol>
        <VCol cols="4">
          <div v-if="everyday.startedAt" class="time-container">
            <div class="time-item">
              {{ formatNumberLeftPad(everyday.startedAt.hour) }}
            </div>
            <span>:</span>
            <div class="time-item">
              {{ formatNumberLeftPad(everyday.startedAt.minute) }}
            </div>
          </div>
          <div v-else class="time-container">
            <div class="time-free">
              выходной
            </div>
          </div>
        </VCol>
        <VCol cols="4">
          <div v-if="everyday.endedAt" class="time-container">
            <div class="time-item">
              {{ formatNumberLeftPad(everyday.endedAt.hour) }}
            </div>
            <span>:</span>
            <div class="time-item">
              {{ formatNumberLeftPad(everyday.endedAt.minute) }}
            </div>
          </div>
          <div v-else class="time-container">
            <div class="time-free">
              выходной
            </div>
          </div>
        </VCol>
      </VRow>
      <VRow
        v-for="(dayTitle, day) in weekDays"
        :key="day"
        align="center"
        class="schedule-row"
        no-gutters
      >
        <VCol class="text-center" cols="4">
          <VDialog v-model="scheduleItems[day].dialog" max-width="50%">
            <template v-slot:activator="{ on, attrs }">
              <VBtn
                v-bind="attrs"
                class="weekday-title"
                :class="{ 'event-none': !changeSchedule }"
                v-on="on"
              >
                {{ dayTitle }}
              </VBtn>
            </template>
            <RestaurantScheduleItemSelection
              :item="scheduleItems[day]"
              @change-only-weekdays="changeEveryday($event, true)"
              @item-changed="changeItem($event, day)"
            />
          </VDialog>
        </VCol>
        <VCol cols="4">
          <div v-if="scheduleItems[day].startedAt" class="time-container">
            <div class="time-item">
              {{ formatNumberLeftPad(scheduleItems[day].startedAt.hour) }}
            </div>
            <span>:</span>
            <div class="time-item">
              {{ formatNumberLeftPad(scheduleItems[day].startedAt.minute) }}
            </div>
          </div>
          <div v-else class="time-container">
            <div class="time-free">
              выходной
            </div>
          </div>
        </VCol>
        <VCol cols="4">
          <div v-if="scheduleItems[day].endedAt" class="time-container">
            <div class="time-item">
              {{ formatNumberLeftPad(scheduleItems[day].endedAt.hour) }}
            </div>
            <span>:</span>
            <div class="time-item">
              {{ formatNumberLeftPad(scheduleItems[day].endedAt.minute) }}
            </div>
          </div>
          <div v-else class="time-container">
            <div class="time-free">
              выходной
            </div>
          </div>
        </VCol>
      </VRow>
    </VCard>
  </div>
</template>
<script>
import { nextWeekDays, RestaurantScheduleItem, weekDays } from '@/serializers/restaurantScheduleSerializer';
import RestaurantScheduleItemSelection from '@/components/RestaurantScheduleItemSelection.vue';

export default {
  name: 'RestaurantScheduleList',
  components: { RestaurantScheduleItemSelection },
  props: {
    value: [Array],
    changeSchedule: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['input'],
  data() {
    const items = {};
    Object.keys(weekDays).forEach((day) => {
      items[day] = {
        dialog: false,
        isEndedNextDay: false,
        startedAt: null,
        endedAt: null,
      };
    });
    return {
      weekDays,
      nextWeekDays,
      everyday: {
        dialog: false,
        startedAt: null,
        endedAt: null,
        isEndedNextDay: false,
      },
      scheduleItems: items,
    };
  },
  methods: {
    changeEveryday(item, onlyWeekDays) {
      this.everyday.dialog = false;
      this.everyday.startedAt = item.startedAt;
      this.everyday.endedAt = item.endedAt;
      this.everyday.isEndedNextDay = item.isEndedNextDay;
      Object.keys(this.scheduleItems).forEach((day) => {
        if (onlyWeekDays && ['saturday', 'sunday'].includes(day)) {
          this.cleanItem(day);
        } else {
          this.changeItem(item, day);
        }
      });
    },
    changeItem(item, day) {
      this.scheduleItems[day].dialog = false;
      this.scheduleItems[day].startedAt = item.startedAt;
      this.scheduleItems[day].endedAt = item.endedAt;
      this.scheduleItems[day].isEndedNextDay = item.isEndedNextDay;
      this.emitChanged();
    },
    cleanItem(day) {
      this.changeItem({
        dialog: false,
        isEndedNextDay: false,
        startedAt: null,
        endedAt: null,
      }, day);
    },
    emitChanged() {
      const newItems = [];
      Object.keys(this.scheduleItems).forEach((day) => {
        const item = this.scheduleItems[day];
        if (item.startedAt && item.endedAt) {
          const newItem = new RestaurantScheduleItem();
          newItem.startedWeekday = day;
          newItem.startedAt = item.startedAt;
          newItem.endedWeekday = item.isEndedNextDay ? nextWeekDays[day] : day;
          newItem.endedAt = item.endedAt;
          newItems.push(newItem);
        }
      });
      this.$emit('input', newItems);
    },
    formatNumberLeftPad(number) {
      return String(number).padStart(2, '0');
    },
  },
  watch: {
    value: {
      deep: true,
      handler(newValue) {
        if (newValue) {
          newValue.forEach((sch) => {
            // eslint-disable-next-line max-len
            this.scheduleItems[sch.startedWeekday].isEndedNextDay = sch.startedWeekday !== sch.endedWeekday;
            this.scheduleItems[sch.startedWeekday].startedAt = sch.startedAt;
            this.scheduleItems[sch.startedWeekday].endedAt = sch.endedAt;
          });
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'src/assets/scss/page.scss';

.header-text {
  text-align: center;
  font-size: 16px;
  display: block;
}
.header-row {
  height: 60px;
}
.everyday-row {
  height: 60px;
  background: #E2E2E2;
}
.schedule-row {
  height: 60px;
}
.weekday-title {
  height: 40px !important;
  width: 100%;
  font-family: $default_font;
  font-weight: 600;
  font-size: 16px;
  background: #EFEFEF;
  border-radius: 3px;
  text-transform: none;
  letter-spacing: 0;
  text-align: left;
  justify-content: left;
}
.time-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $default_font;
  font-weight: 600;
  font-size: 16px;
  .time-item {
    width: 45px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EFEFEF;
    border-radius: 3px;
    margin: 0 5px;
  }
  .time-free {
    width: 110px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #EFEFEF;
    border-radius: 3px;
    margin: 0 5px;
  }
}
.event-none {
  pointer-events: none;
}
</style>
