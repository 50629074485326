<template>
  <div>
    <VSimpleTable class="elevation-2">
      <thead>
        <tr class="titles-thead">
          <th class="text-center" />
          <th class="text-left city-column">
            <span>Город</span>
          </th>
          <th class="text-left">
            <span>Ресторан</span>
          </th>
          <th class="text-center delivery-service-column">
            <span>Тип</span>
          </th>
          <th class="text-center">
            <span>Состояние</span>
          </th>
          <th class="text-center">
            <span>График</span>
          </th>
          <th class="text-center">
            <span>Оплата</span>
          </th>
        </tr>
        <tr class="search-thead">
          <th />
          <th>
            <VSelect
              v-model="filter.city"
              background-color="#transparent"
              dark
              dense
              hide-details
              item-text="title"
              item-value="pk"
              :items="cities"
              outlined
              placeholder="Все"
              @change="searchBy()"
            />
          </th>
          <th>
            <VTextField
              v-model="filter.title"
              background-color="transparent"
              clearable
              dark
              dense
              hide-details
              outlined
              placeholder="Название ресторана"
              prepend-inner-icon="mdi-magnify"
              @change="searchBy()"
            />
          </th>
          <th>
            <VSelect
              v-model="filter.deliveryService"
              background-color="#transparent"
              dark
              dense
              hide-details
              :items="deliveryChoices"
              outlined
              placeholder="Все"
              @change="searchBy()"
            />
          </th>
          <th>
            <VSelect
              v-model="filter.state"
              background-color="#transparent"
              dark
              dense
              hide-details
              item-text="title"
              item-value="value"
              :items="stateChoices"
              outlined
              placeholder="Все"
              @change="searchBy()"
            />
          </th>
          <th />
          <th />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="restaurant in restaurants"
          :key="restaurant.id"
        >
          <td class="text-center">
            <div class="d-flex justify-center">
              <VCheckbox
                v-model="restaurant.isInHub"
                :class="{ none: hubId && !isEdit }"
                color="green"
                @change="addRestaurant(restaurant.id, restaurant.isInHub)"
              />
            </div>
          </td>
          <td class="text-left">
            <span>{{ restaurant.city }}</span>
          </td>
          <td class="text-left">
            <span>{{ restaurant.title }}</span>
          </td>
          <td class="text-center">
            <span>{{ restaurant.deliveryTitle }}</span>
          </td>
          <td class="text-center">
            <div class="d-flex justify-center">
              <VSwitch
                v-model="restaurant.state"
                class="my-auto"
                color="green"
                :disabled="!hubId || isEdit"
                hide-details
                @change="changeRestaurantState(restaurant.id, restaurant.state)"
              />
            </div>
          </td>
          <td class="text-center">
            <VBtn
              class="default-btn white--text"
              color="green"
              @click="showSchedule(restaurant.schedule)"
            >
              Посмотреть график
            </VBtn>
          </td>
          <td class="text-center">
            <span>{{ restaurant.paymentMethods }}</span>
          </td>
        </tr>
      </tbody>
    </VSimpleTable>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { fetchRestaurantChoices, updateRestaurantState } from '@/api/api';

const defaultFilter = () => ({
  city: null,
  title: '',
  deliveryService: '',
  state: null,
});

export default {
  props: {
    hubId: null,
    isEdit: {
      type: Boolean,
      default: false,
    },
    restaurants: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ['filter', 'add-restaurant', 'show-schedule', 'filter-hubs'],
  data() {
    return {
      deliveryChoices: [],
      stateChoices: [
        {
          title: 'Вкл',
          value: true,
        },
        {
          title: 'Выкл',
          value: false,
        },
      ],
      filter: {
        city: null,
        title: '',
        deliveryService: '',
        state: null,
      },
    };
  },
  created() {
    this.getCities();
    fetchRestaurantChoices().then((choices) => {
      this.deliveryChoices = choices.deliveryChoices;
    });
  },
  computed: {
    ...mapGetters(['cities']),
  },
  methods: {
    ...mapActions(['getCities']),
    setupDefaults() {
      this.filter = defaultFilter();
    },
    searchBy() {
      this.$emit('filter', this.filter);
    },
    addRestaurant(id, value) {
      this.$emit('add-restaurant', {
        id,
        value,
      });
    },
    showSchedule(data) {
      this.$emit('show-schedule', data);
    },
    async changeRestaurantState(id, isRestaurantActive) {
      const restaurantState = isRestaurantActive ? 'active' : 'delivery_does_not_work';
      await updateRestaurantState(id, restaurantState).then(() => {
        this.$emit('filter-hubs');
      });
    },
  },
};
</script>

<style scoped lang='scss'>
@import 'src/assets/scss/mixin.scss';

.titles-thead th {
  color: $default_dark_gray_color !important;
}
.search-thead th {
  background-color: $default_dark_blue_color;
  padding-top: 12px;
  padding-bottom: 12px;
}
.active {
  color: white;
  background: $default_green_color !important;
}
.none {
  display: none;
}
</style>
