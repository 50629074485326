<template>
  <VCard class="time-range-container">
    <VRow no-gutters>
      <VCol>
        <VRangeSlider
          v-model="range"
          class="time-range-selector"
          :class="isHoliday ? 'disabled-slider' : null"
          color="#4caf50"
          :disabled="isHoliday"
          hide-details
          :max="max"
          :min="min"
          :step="step"
          :thumb-label="!isHoliday ? 'always' : false "
          :thumb-size="45"
          :tick-labels="tickLabels"
          :tick-size="0"
          track-color="red"
        >
          <template v-slot:thumb-label="{ value }">
            <span class="thumb-label">{{ valueToSTRTime(value) }}</span>
          </template>
        </VRangeSlider>
      </VCol>
    </VRow>
    <VRow
      align="center"
      class="mt-4"
      no-gutters
    >
      <VCol>
        <VCheckbox
          v-model="isHoliday"
          class="ml-6 checkbox"
          color="#4caf50"
          label="Выходной"
        />
      </VCol>
      <VCol>
        <VCheckbox
          v-model="isOnlyWeekdays"
          class="ml-6 checkbox"
          color="#4caf50"
          label="Только будни"
        />
      </VCol>
      <VSpacer />
      <VCol cols="auto">
        <VBtn
          class="default-btn button-save"
          color="#4caf50"
          rounded
          @click="saveChanges()"
        >
          Изменить
        </VBtn>
      </VCol>
    </VRow>
  </VCard>
</template>

<script>
export default {
  name: 'RestaurantScheduleItemSelection',
  props: {
    item: [Object],
  },
  data() {
    return {
      dayMinutes: (24 * 60), // convert 23h59m to minutes
      startedTime: null,
      endedTime: null,
      isEndedNextDay: false,
      min: 0,
      step: 15,
      range: [0, 0],
      isHoliday: false,
      isOnlyWeekdays: false,
    };
  },
  created() {
    this.setupRange();
  },
  computed: {
    tickLabels() {
      const count = (this.dayMinutes * 2) / this.step;
      return Array.from(Array(count), (_, i) => (!(i % 12) ? this.valueToSTRTime(i * this.step) : ''));
    },
    max() {
      return this.dayMinutes + Math.min(this.range[0] - 1, this.dayMinutes);
    },
  },
  methods: {
    setupRange() {
      this.isEndedNextDay = this.item ? this.item.isEndedNextDay : false;
      if (this.item && this.item.startedAt && this.item.endedAt) {
        const left = this.item.startedAt.hour * 60 + this.item.startedAt.minute;
        let right = this.item.endedAt.hour * 60 + this.item.endedAt.minute;
        if (this.isEndedNextDay) {
          right += this.dayMinutes;
        }
        this.isHoliday = false;
        this.range = [left, right];
      } else {
        this.isHoliday = true;
        this.range = [this.min, this.max];
      }
    },
    convertMinutesToHourMinutes(minutes) {
      const hour = Math.floor(minutes / 60);
      const minute = minutes % 60;
      return { hour, minute };
    },
    valueToTime(value) {
      const isNextDay = value >= this.dayMinutes;
      const time = isNextDay ? value - this.dayMinutes : value;
      return this.convertMinutesToHourMinutes(time);
    },
    valueToSTRTime(value) {
      const timeHM = this.valueToTime(value);
      timeHM.hour = String(timeHM.hour).padStart(2, '0');
      timeHM.minute = String(timeHM.minute).padStart(2, '0');
      return `${timeHM.hour}:${timeHM.minute}`;
    },
    saveChanges() {
      const startedAt = !this.isHoliday ? this.valueToTime(this.range[0]) : null;
      const endedAt = !this.isHoliday ? this.valueToTime(this.range[1]) : null;
      const isEndedNextDay = !this.isHoliday ? this.range[1] >= this.dayMinutes : false;
      const emitName = this.isOnlyWeekdays ? 'change-only-weekdays' : 'item-changed';
      this.$emit(emitName, { isEndedNextDay, startedAt, endedAt });
    },
  },
  watch: {
    item() {
      this.setupRange();
    },
    isEndedNextDay() {
      if (this.range[1] > this.max) {
        this.range[1] = this.max;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

.time-range-container {
  height: 200px;
}
.time-range-selector {
  padding: 70px 0 0 0;
  margin: 0 25px;
  height: 100px;
}
.thumb-label {
  font-family: $default_bold_font;
}
.checkbox {
  ::v-deep .v-label{
    font-family: $default_font;
    font-weight: 600;
    font-size: 15px;
    color: #4F4F4F
  }
}
.button-save {
  font-size: 16px;
  color: white;
  width: 150px;
  margin: 0 25px;
}
.disabled-slider {
  ::v-deep {
    .v-slider__thumb {
      background: #E43A35 !important;
    }
    .v-slider__track-background {
      background: #E43A35 !important;
    }
  }
}
::v-deep {
  .v-slider__tick-label {
    font-family: $default_font;
    font-weight: 600;
    font-size: 12px;
  }
}
</style>
