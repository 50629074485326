<template>
  <VDialog v-model="dialog">
    <VCard class="py-8 px-8">
      <div ref="title" class="pb-6">
        <VFlex
          class="mr-auto"
          xs4
        >
          <span v-if="hubToEdit.id && !isEdit" class="page_title">{{ hubToEdit.title }}</span>
          <VTextField
            v-else
            v-model="title"
            color="green"
            placeholder="Введите название метки"
          />
          <div v-if="validation.title" class="validation-alert">
            Введите название метки
          </div>
        </VFlex>
        <VBtn
          class="modal-close-icon"
          icon
          @click="close"
        >
          <VIcon large>
            mdi-close
          </VIcon>
        </VBtn>
      </div>
      <div class="d-flex align-center pb-12">
        <p class="mb-0 mr-6">
          Сужение
        </p>
        <VSwitch
          v-model="hubToEdit.isReducedAreaActive"
          class="my-auto mr-8"
          color="green"
          :disabled="!hubToEdit.id || isEdit"
          hide-details
          @change="updateHub(
            {id: hubToEdit.id, isReducedAreaActive: hubToEdit.isReducedAreaActive}
          )"
        />
        <p class="mb-0 mr-6">
          Оплата
        </p>
        <VBtn
          v-for="(value, key) in paymentTypeTitles"
          :key="key"
          class="default-btn mr-3"
          :class="{ active: !!hubToEdit.paymentMethods[key] }"
          :disabled="!hubToEdit.id || isEdit"
          :outlined="!hubToEdit.paymentMethods[key]"
          rounded
          @click="
            hubToEdit.paymentMethods[key] = !hubToEdit.paymentMethods[key];
            updateHub({id: hubToEdit.id, paymentMethods: hubToEdit.paymentMethods});
          "
        >
          {{ value }}
        </VBtn>
        <VBtn
          v-if="hubToEdit.id"
          class="default-btn white--text ml-auto"
          color="red"
          rounded
          @click="$emit('show-warning', {id: hubToEdit.id, title: hubToEdit.title});"
        >
          Удалить метку
        </VBtn>
      </div>
      <div class="d-flex align-center pb-4">
        <p
          class="red--text mb-0 mr-6"
          :class="{ 'text--secondary': isEdit || !hubToEdit.id }"
        >
          Активно {{ hubToEdit.activeRestsCount || 0 }}/{{ hubToEdit.allRestsCount || 0 }}
        </p>
        <VBtn
          class="default-btn white--text  mr-3"
          color="green"
          :disabled="!hubToEdit.id || isEdit"
          @click="updateHub({id: hubToEdit.id, delivery: true})"
        >
          Включить все
        </VBtn>
        <VBtn
          class="default-btn white--text"
          color="red"
          :disabled="!hubToEdit.id || isEdit"
          @click="updateHub({id: hubToEdit.id, delivery: false})"
        >
          Отключить все
        </VBtn>
      </div>
      <HubRestaurantsTable
        ref="hubRestaurantsTable"
        class="mb-6"
        :hub-id="hubToEdit.id"
        :is-edit="isEdit"
        :restaurants="restaurants.data"
        @add-restaurant="addRestaurant"
        @filter="updateSearchParams"
        @filter-hubs="filterHubs"
        @show-schedule="showSchedule"
      />
      <VPagination
        v-model="searchParams.page"
        class="pb-3"
        color="green"
        :length="restaurants.totalPages"
        :total-visible="7"
      />
      <div class="d-flex justify-end">
        <VBtn
          v-if="!hubToEdit.id"
          class="default-btn white--text mr-4"
          color="#1E262D"
          rounded
          @click="close()"
        >
          Отменить
        </VBtn>
        <VBtn
          v-if="!hubToEdit.id || isEdit"
          class="default-btn white--text"
          color="#4caf50"
          rounded
          @click="validateSaveHub()"
        >
          Сохранить
        </VBtn>
        <VBtn
          v-if="!isEdit && hubToEdit.id"
          class="default-btn white--text"
          color="#4caf50"
          rounded
          @click="editHub()"
        >
          Редактировать
        </VBtn>
      </div>
    </VCard>
  </VDialog>
</template>

<script>
import HubRestaurantsTable from '@/components/HubRestaurantsTable.vue';
import { paymentTypeTitles } from '@/serializers/restaurantSerializer';
import {
  createHub,
  updateHub,
  deleteHub,
  fetchHubRestaurants,
} from '@/api/api';

const defaultHubValue = () => ({
  id: null,
  title: '',
  isReducedAreaActive: undefined,
  paymentMethods: {},
  allRestsCount: null,
  activeRestsCount: null,
  restaurants: {},
});

const defaultValidation = () => ({
  title: false,
});

const deserializedFilter = (data) => ({
  city_id: data.city || undefined,
  title: data.title || undefined,
  will_be_delivered_by: data.deliveryService || undefined,
  is_active: data.state || undefined,
});

export default {
  components: {
    HubRestaurantsTable,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    hub: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['show-schedule', 'update', 'filter-hubs', 'appearance', 'show-warning'],
  data() {
    return {
      hubToEdit: {
        id: null,
        title: '',
        isReducedAreaActive: undefined,
        paymentMethods: {},
        allRestsCount: null,
        activeRestsCount: null,
        restaurants: {},
      },
      paymentTypeTitles,
      dialog: this.show,
      isEdit: false,
      title: '',
      restaurants: {},
      searchParams: {
        hub_id: null,
        is_in_hub: undefined,
        page: 1,
      },
      validation: {
        title: false,
      },
    };
  },
  mounted() {
    if (this.hub.id) {
      this.hubToEdit = { ...JSON.parse(JSON.stringify(this.hub)) };
    }
  },
  methods: {
    close() {
      this.dialog = false;
      this.isEdit = false;
    },
    setupDefaults() {
      this.validation = defaultValidation();
      this.title = this.hubToEdit.title;
      this.searchParams = {
        hub_id: this.hubToEdit.id,
        is_in_hub: true,
        page: 1,
      };
    },
    editHub() {
      this.searchParams.is_in_hub = undefined;
      this.isEdit = !this.isEdit;
    },
    filterRestaurants() {
      fetchHubRestaurants(this.searchParams).then((response) => {
        this.restaurants = response;
      });
    },
    updateSearchParams(searchParams) {
      Object.assign(this.searchParams, deserializedFilter(searchParams));
      this.filterRestaurants();
    },
    addRestaurant(data) {
      this.hubToEdit.restaurants[data.id] = data.value;
    },
    showSchedule(data) {
      this.$emit('show-schedule', data);
    },
    updateHub(updateParams) {
      this.$emit('update', updateParams);
    },
    filterHubs() {
      this.$emit('filter-hubs');
    },
    validateSaveHub() {
      if (this.title) {
        this.saveHub();
      } else {
        this.validation.title = true;
        this.$refs.title.scrollIntoView({ block: 'center', behavior: 'smooth' });
      }
    },
    async saveHub() {
      this.hubToEdit.title = this.title;
      if (this.hubToEdit.id) {
        await updateHub(this.hubToEdit).then(() => {
          this.$emit('filter-hubs');
        });
      } else {
        await createHub(this.hubToEdit).then(() => {
          this.$emit('filter-hubs');
        });
      }
      this.hubToEdit = defaultHubValue();
      this.close();
    },
    async removeHub(id) {
      await deleteHub(id).then(() => {
        this.$emit('filter-hubs');
      });
      this.close();
    },
  },
  watch: {
    hub(value) {
      if (value.id) {
        this.hubToEdit = { ...JSON.parse(JSON.stringify(value)) };
      }
    },
    show(bool) {
      if (bool) {
        this.setupDefaults();
      }
      this.dialog = bool;
    },
    dialog(bool) {
      if (!bool) {
        this.$refs.hubRestaurantsTable.setupDefaults();
        this.$emit('appearance', bool);
      }
    },
    restaurants: {
      deep: true,
      handler() {
        this.restaurants.data.forEach((restaurant) => {
          if (this.hubToEdit.restaurants[restaurant.id]) {
            // eslint-disable-next-line no-param-reassign
            restaurant.isInHub = this.hubToEdit.restaurants[restaurant.id];
          }
        });
      },
    },
    searchParams: {
      deep: true,
      handler() {
        this.filterRestaurants();
      },
    },
  },
};
</script>

<style scoped lang='scss'>
@import 'src/assets/scss/mixin.scss';

.modal-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
.active {
  color: white;
  background: $default_green_color !important;
}
</style>
