<template>
  <VCard class="ma-7" flat>
    <HubModal
      ref="hubRestaurants"
      :hub="hub || {}"
      :show="showModal"
      @appearance="showModal = $event"
      @filter-hubs="filterHubs"
      @show-schedule="openSchedule"
      @show-warning="openWarning"
      @update="updateHub"
    />
    <VDialog
      v-model="showWarning"
      width="450"
    >
      <VCard class="py-8 px-8">
        <div class="mb-2">
          <span class="medium-title">
            Удалить метку {{ hubToDelete.title }}?
          </span>
        </div>
        <div>
          <p class="mb-6">
            Данное действие необратимо и приведёт к
            полному удалению. Вы уверены?
          </p>
          <div class="d-flex">
            <VBtn
              class="mr-4 px-8 default-btn white--text"
              color="#1E262D"
              rounded
              @click="closeWarning()"
            >
              Отмена
            </VBtn>
            <VBtn
              class="px-8 default-btn white--text"
              color="red"
              rounded
              @click="acceptRemoveHub()"
            >
              Удалить
            </VBtn>
          </div>
        </div>
      </VCard>
    </VDialog>
    <VDialog
      v-model="showMessage"
      width="575"
    >
      <VCard class="py-8 px-8">
        <div class="pb-8">
          <span class="medium-title">
            Ваши действия повлияют на все заведения с данной меткой
          </span>
          <VBtn
            class="modal-close-icon"
            icon
            @click="closeMessage()"
          >
            <VIcon>mdi-close</VIcon>
          </VBtn>
        </div>
        <div>
          <p>У Вас есть пересечение с несколькими метками:</p>
          <span v-for="intersection in intersections" :key="intersection.id">
            {{ intersection.restaurant_title }} - {{ intersection.hub_titles }}<br>
          </span>
        </div>
      </VCard>
    </VDialog>
    <VDialog
      v-model="showSchedule"
      width="500"
    >
      <RestaurantScheduleList
        v-model="schedule"
        :change-schedule="false"
      />
    </VDialog>
    <HubsTariffDialog
      :dialog="showTariffs"
      :is-editing="isEditing"
      :item="incomingHub"
      :tariffs="tariffs"
      @close="closeModalTariff();"
      @update="updateTariff"
    />
    <div class="d-flex pb-5">
      <span class="page_title mr-auto">Метки заведений</span>
      <VBtn
        class="default-btn white--text"
        color="#4caf50"
        rounded
        @click="openModal()"
      >
        <VIcon left>
          mdi-plus-circle
        </VIcon>
        Добавить метку
      </VBtn>
    </div>
    <HubsTable
      class="mb-6"
      :hubs="hubs.data"
      @filter="updateSearchParams"
      @intersection="openMessage"
      @modal="openModal"
      @tariffEdit="openModalTariff"
      @tariffToggle="toggleTariff"
      @update="updateHub"
    />
    <VPagination
      v-model="searchParams.page"
      color="green"
      :length="hubs.totalPages"
      :total-visible="7"
    />
  </VCard>
</template>

<script>
import HubsTable from '@/components/HubsTable.vue';
import HubModal from '@/components/HubModal.vue';
import RestaurantScheduleList from '@/components/RestaurantScheduleList.vue';
import {
  fetchHubs,
  patchHub,
  fetchHotDeliveryTariffs,
  updateHub,
} from '@/api/api';
import HubsTariffDialog from '@/components/HubsTariffDialog.vue';

const deserializedChoice = (data) => ({
  is_reduced_area_active: data.isReducedAreaActive,
  payment_methods: data.paymentMethods,
  delivery: data.delivery,
});

export default {
  components: {
    HubsTable,
    HubModal,
    RestaurantScheduleList,
    HubsTariffDialog,
  },
  data() {
    return {
      hub: {},
      hubs: {},
      hubToDelete: {},
      intersections: [],
      searchParams: {
        page: 1,
      },
      showModal: false,
      showWarning: false,
      showMessage: false,
      showSchedule: false,
      schedule: [],
      paramsHotTariff: {
        search_title: '',
        limit: 5000,
      },
      tariffs: [],
      incomingHub: null,
      showTariffs: false,
      isEditing: false,
    };
  },
  created() {
    this.filterHubs();
    this.hotTariffList();
  },
  methods: {
    filterHubs() {
      fetchHubs(this.searchParams).then((response) => {
        this.hubs = response;
      });
    },
    updateSearchParams(searchParams) {
      Object.assign(this.searchParams, searchParams, { page: 1 });
      this.filterHubs();
    },
    updateHubInModal() {
      if (this.hub) {
        this.hub = this.hubs.data.find(({ id }) => id === this.hub.id);
      }
    },
    openModal(data) {
      this.hub = data;
      this.showModal = true;
    },
    openWarning(data) {
      this.hubToDelete = data;
      this.showWarning = true;
    },
    openMessage(data) {
      this.intersections = data;
      this.showMessage = true;
    },
    openSchedule(data) {
      this.schedule = data;
      this.showSchedule = true;
    },
    closeWarning() {
      this.showWarning = false;
    },
    closeMessage() {
      this.showMessage = false;
    },
    acceptRemoveHub() {
      this.$refs.hubRestaurants.removeHub(this.hubToDelete.id);
      this.closeWarning();
    },
    updateHub(data) {
      patchHub(data.id, deserializedChoice(data)).then((response) => {
        if (response.intersections.length > 0) {
          this.openMessage(response.intersections);
        }
        this.filterHubs();
        const containsDelivery = Object.keys(data).filter(key => key === 'delivery').length === 1;
        if (containsDelivery) {
          this.$refs.hubRestaurants.filterRestaurants();
        }
      });
    },
    hotTariffList() {
      fetchHotDeliveryTariffs(this.paramsHotTariff).then((response) => {
        this.tariffs = response.data;
      });
    },
    openModalTariff(hub) {
      this.incomingHub = hub;
      this.showTariffs = true;
      this.isEditing = hub.hotTariff !== null;
    },
    closeModalTariff() {
      this.incomingHub = null;
      this.showTariffs = false;
      this.filterHubs();
    },
    updateTariff(hub) {
      if (hub) {
        const payload = {
          title: hub.title,
          restaurants: hub.restaurants,
          id: hub.id,
          hot_tariff_id: hub.hotTariff?.id || -1,
        };
        updateHub(payload).finally(() => {
          this.filterHubs();
        });
      }
    },
    toggleTariff(hub) {
      if (hub && hub.hotTariff) {
        const { ...currentHub } = hub;
        currentHub.hotTariff = null;
        this.updateTariff(currentHub);
      } else {
        this.openModalTariff(hub);
      }
    },
  },
  watch: {
    searchParams: {
      deep: true,
      handler() {
        this.filterHubs();
      },
    },
    hubs: {
      deep: true,
      immediate: false,
      handler() {
        this.updateHubInModal();
      },
    },
  },
};
</script>

<style scoped>
.modal-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
