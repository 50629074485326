<template>
  <div>
    <VSimpleTable class="elevation-2">
      <thead>
        <tr class="search-thead">
          <th colspan="2">
            <VTextField
              v-model="searchedTitle"
              background-color="transparent"
              clearable
              dark
              dense
              hide-details
              outlined
              placeholder="Введите название метки"
              prepend-inner-icon="mdi-magnify"
              @change="searchBy()"
            />
          </th>
          <th colspan="4"/>
        </tr>
        <tr class="titles-thead">
          <th class="text-left">
            <span>Название метки</span>
          </th>
          <th class="text-center">
            <span>Состояние</span>
          </th>
          <th class="text-center">
            <span>Сужение</span>
          </th>
          <th class="text-center">
            <span>Повышенный тариф</span>
          </th>
          <th class="text-center">
            <span>Текущий  тариф</span>
          </th>
          <th class="text-center">
            <span>Оплата</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="hub in hubs"
          :key="hub.id"
        >
          <td>
            <button
              class="modal-btn"
              @click="$emit('modal', hub)"
            >
              {{ hub.title }}
            </button>
          </td>
          <td class="text-left">
            <div class="mt-3 mb-1">
              <span class="red--text">
                Активно {{ hub.activeRestsCount }}/{{ hub.allRestsCount }}
              </span>
            </div>
            <div class="pb-3">
              <VBtn
                class="default-btn white--text mr-3 my-1"
                color="green"
                @click="updateHub({id: hub.id, delivery: true})"
              >
                Включить все
              </VBtn>
              <VBtn
                class="default-btn white--text my-1"
                color="red"
                @click="updateHub({id: hub.id, delivery: false})"
              >
                Отключить все
              </VBtn>
            </div>
          </td>
          <td class="text-center">
            <div class="d-flex justify-center">
              <VSwitch
                v-model="hub.isReducedAreaActive"
                class="my-auto"
                color="green"
                hide-details
                @change="updateHub({id: hub.id, isReducedAreaActive: hub.isReducedAreaActive})"
              />
            </div>
          </td>
          <td class="text-center">
            <div class="d-flex justify-center">
              <VSwitch
                v-model="hub.isHotTariffActive"
                class="my-auto"
                color="green"
                hide-details
                @change="$emit('tariffToggle', hub)"
              />
            </div>
          </td>
          <td class="">
            <div v-if="hub.hotTariff" class="d-flex justify-space-between">
              <span>{{ hub.hotTariff.title }}</span>
              <VBtn
                class="modal-btn"
                icon
                @click="$emit('tariffEdit', hub)"
              >
                <VIcon color="#D0021B">
                  mdi-pencil
                </VIcon>
              </VBtn>
            </div>
          </td>
          <td class="text-center">
            <div class="d-inline-flex justify-center my-auto">
              <VBtn
                v-for="(value, key) in paymentTypeTitles"
                :key="key"
                class="default-btn mr-3"
                :class="{ active: hub.paymentMethods[key]}"
                :outlined="!hub.paymentMethods[key]"
                rounded
                @click="
                  hub.paymentMethods[key] = !hub.paymentMethods[key];
                  updateHub({id: hub.id, paymentMethods: hub.paymentMethods});
                "
              >
                {{ value }}
              </VBtn>
            </div>
          </td>
        </tr>
      </tbody>
    </VSimpleTable>
  </div>
</template>

<script>
import { paymentTypeTitles } from '@/serializers/restaurantSerializer';

export default {
  props: {
    hubs: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ['modal', 'tariffToggle', 'tariffEdit', 'filter', 'update'],
  data() {
    return {
      paymentTypeTitles,
      searchedTitle: '',
    };
  },
  methods: {
    searchBy() {
      this.$emit('filter', { title: this.searchedTitle });
    },
    updateHub(updateParams) {
      this.$emit('update', updateParams);
    },
  },
};
</script>

<style scoped lang='scss'>
@import 'src/assets/scss/mixin.scss';

.titles-thead th {
  color: $default_dark_gray_color !important;
  background-color: $default_light_gray_color;
}
.search-thead th {
  background-color: $default_dark_blue_color;
  padding-top: 12px;
  padding-bottom: 12px;
}
.active {
  color: white;
  background: $default_green_color !important;
}
.modal-btn:hover {
  color: $default_green_color !important;
}
</style>
